import { Box, Button, FormControl, IconButton, InputAdornment, ListItemButton, ListItemText, Menu, MenuItem, Modal, OutlinedInput, Select } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import { BiDotsHorizontalRounded, BiUserCheck, BiUserX } from 'react-icons/bi'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux';
import { openHandler } from '../Components/Redux/Slice/common/overlaySlice';
import { IoMdAddCircle } from 'react-icons/io';
import { Controller, useForm } from 'react-hook-form';
import { addUser, getUsers } from '../Components/Redux/Slice/Users/usersSlice';
import { activeUser, disactiveUser } from '../Components/Redux/Slice/Users/toggleActiveSlice';
import Overlay from '../Components/Common/overlay';
import { dateFormate, formatDateToUserTimezone } from '../Components/Common/dateFormate';
import { getRoles } from '../Components/Redux/Slice/Roles/roles';
import { getBranches } from '../Components/Redux/Slice/Branches/branches';
import ColumnSelection from '../Components/Common/columnSelection';
import Astrisks from '../Components/Common/astrisk'
import { useRemoteSort } from '../Components/Common/sortHook';
import NoDataEmoji from '../Components/Common/nodataEmoje';
import { TableStyles } from '../Components/Common/tableStyle';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { resetPage, resetSearch, resetSort } from '../Components/Redux/Slice/ResetPagination/resetPagination';
import { BsDot } from 'react-icons/bs';

//Custome Call UI for the data table action column
function ActionCell(data) {
    const [page, setpage] = useState(1)
    const [row, setrow] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // console.log(data);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch()
    const status = useSelector(state => state?.users)

    const [loading, setloading] = useState(false)

    const handleActive = () => {
        dispatch(activeUser(data?.id)).then(() => {
            dispatch(getUsers(page, row))
            dispatch(resetPage());
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
        })
            .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)

    }

    const handleDisactive = () => {
        dispatch(disactiveUser(data?.id)).then(() => {
            dispatch(getUsers(page, row))
            dispatch(resetPage());
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
        })
            .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)
    };

    useEffect(() => {
        setTimeout(() => {
            setloading(false)
        }, 3000);
    }, [loading])

    // console.log(loading);
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
            >
                <div className={`[&>li]:mb-1 [&>li>svg]:text-xl rounded-md overflow-hidden [&>li]:flex [&>li]:items-center [&>li]:gap-x-2 ${localStorage?.getItem('i18nextLng') === 'ar' ? '[&>li]:flex-row-reverse' : '[&>li]:flex-row'}`}>
                    {data?.status === 'ON_HOLD' ?
                        <>
                            <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('i18nextLng') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>
                            <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('i18nextLng') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>
                        </>

                        : data?.status === 'ACTIVE' ?
                            <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('i18nextLng') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>

                            : data?.status === 'DEACTIVATED' ?
                                <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('i18nextLng') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>

                                : null}

                </div>
            </Menu>
        </div>
    );
}

const Users = () => {
    const { t, i18n } = useTranslation()
    const form = useForm()
    const { register, control, handleSubmit, formState, setValue, getValues, reset } = form
    const { errors, isDirty } = formState
    const [showCalander, setshowCalender] = useState(false) //for displaying the date picker when button clicked
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [region, setregion] = useState(""); // for storing the Region
    const [type, settype] = useState(""); // for storing the Region
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    const [roleType, setroleType] = useState(null)
    const [roleName, setroleName] = useState(null)
    const [branch, setbranch] = useState(null)

    const { roles } = useSelector(state => state?.roles)
    const { branches } = useSelector(state => state?.branches)

    const { currentPage } = useSelector((state) => state?.resetPagination);
    const { toggle, search, sort } = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    useEffect(() => {
        setsearchValue('')
    }, [search])

    // console.log(roles);
    useEffect(() => {
        dispatch(getUsers({ page, size }))
        dispatch(getRoles())
        const getBranchesHandler = () => {
            let page = 1,
                size = 500
            dispatch(getBranches({ page, size }))
        }
        getBranchesHandler()
    }, [])

    useEffect(() => {
        // console.log(roleType);
    }, [roleType, setValue])


    const handleroleTypeSelectChange = (event) => {
        const { target: { value }, } = event;
        setroleType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setroleName(value?.roleName)
        setValue('roleId', value?.id, { shouldValidate: true })
    };

    const handleBranchSelectChange = (event) => {
        const { target: { value }, } = event;
        setbranch(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setValue('branchId', value, { shouldValidate: true })
    };

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };
    // useEffect(() => {
    //     dispatch(getUsers({page,row,searchValue,col,dir}))
    // }, [dispatch, page,row,searchValue])


    //data table section

    const data = users?.users?.data

    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: false,
        //     width : '70px'
        // },
        {
            id: "name",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الاسم' : 'Name'}`,
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
            // minWidth : '150px'
        },
        {
            id: "gender",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الجنس' : 'Gender'}`,
            cell: row => <span data-tag="allowRowEvents" className="capitalize">{row?.gender === 'male' ? t('common.male') : t('common.female')}</span>,
            sortable: false,
            // minWidth : '120px'
        },
        {
            id: "email",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => row.email,
            sortable: false,
        },
        {
            id: "phone",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'رقم التليفون' : 'Phone'}`,
            selector: row => row.mobileNumber,
            sortable: false,
            // minWidth : '150px'
        },
        {
            id: "role",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الدور' : 'Role'}`,
            selector: row => <span title={row?.roleName?.toLowerCase()?.replace(/_/g, " ")} className='capitalize font-semibold'>{localStorage.getItem('i18nextLng') === 'ar' ? row?.roleNameAr?.toLowerCase()?.replace(/_/g, " ") : row?.roleName?.toLowerCase()?.replace(/_/g, " ")}</span>,
        },
        {
            id: "lastLogin",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اخر تسجيل دخول' : 'Last Login'}`,
            cell: (row) => formatDateToUserTimezone(row.lastLogin),
            sortable: true,
        },
        {
            id: "status",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الحالة' : 'Status'}`,
            // selector: row => row.status,
            // sortable: true,
            cell: row => <span className={`block px-2 py-[6px] rounded-md bg-opacity-10 min-w-[120px] font-semibold text-center capitalize ${row?.status === 'ACTIVE' ? 'text-mainGreen bg-mainGreen' : row?.status === 'DEACTIVATED' ? 'text-mainRed bg-mainRed' : row?.status === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : null}`}>
                {
                    row?.status === 'ACTIVE' ? t('common.active') :
                        row?.status === 'DEACTIVATED' ? t('common.deactivated') :
                            row?.status === 'ON_HOLD' ? t('common.onHold') : null
                }
            </span>
        },
        {
            id: "actions",
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: row => ActionCell(row)

        },
    ];

    // console.log(data);

    // open calander overlay
    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }
    // console.log(dateOverlay);


    const [openModal, setopenModal] = useState(false);
    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setopenModal(false)
        reset()
        setroleType([])
        setbranch([])
        setroleName(null)
    };
    const style = {
        position: 'absolute',
        top: '47%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 0,
        borderRadius: 5
    };

    // handle modal form

    const submitHandler = async (data) => {

        await dispatch(addUser(data)).then(() => {
            dispatch(getUsers({ page, size }))
            dispatch(resetPage())
            setroleType([])
            setbranch([])
            setroleName(null)
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
        }).then(() => reset()).then(() => handleCloseModal())
        // console.log(data);
    }
    // console.log(users);

    const handleSearch = () => {
        dispatch(getUsers({ searchValue })).then(() => dispatch(resetPage()))
    }

    const handleReset = () => {
        dispatch(getUsers({ page, size })).then(() => dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
        //   console.log(searchValue);
    }, [searchValue])



    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };

    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     {name:'User Name'},
    //     {name:'last_login'},
    //     // {name:'Price Before'},
    //     // {name:'Calories'},
    //     // {name:'created at'},
    //     // {name:'Final Amount'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const { handleRemoteSort, icon } = useRemoteSort(getUsers, dispatch, page, size, searchValue)


    // console.log(errors);
    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['name', 'gender', 'email', 'phone', 'role', 'lastLogin', 'status', 'actions']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['name', 'gender', 'email', 'phone', 'role', 'lastLogin', 'status', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return (
        <article className="users-wrapper">
            <section className="users-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('users.title')}</h1>
            </section>

            <section className={`users-control-wrapper flex ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-between items-center mt-5 w-full`}>

                {/* <div className='flex gap-x-3'>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>

                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                        
                    </div>
                </div> */}

                <div className="users-search-wrapper">
                    <FormControl
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ m: 1, width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            value={searchValue}
                            placeholder={localStorage.getItem('i18nextLng') === 'ar' ? 'ابحث في المستخدمين...' : 'Search Users...'}
                            onChange={(e) => {
                                setsearchValue(e.target.value)
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                    dispatch(resetPage())
                                }
                            }}
                            startAdornment={
                                <InputAdornment position="start">

                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>

                <div className={`flex items-center gap-x-3 ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <Button onClick={handleOpenModal} className='capitalize text-white font-semibold bg-mainGreen rounded-md py-[6px] flex justify-between items-center gap-x-2 font-playfair'><IoMdAddCircle className={`w-7 h-7 rounded-[50%]`} />{t('users.add_users_btn')}</Button>

                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                        {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative rounded-md">

                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={size}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={users?.users?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            onSort={handleRemoteSort}
                            sortFunction={null}
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage={true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />

                        {users?.loading ?
                            <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                                <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                            </div> : null}
                    </>
                }

            </section>

            <>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="menu-item-delete-modal-title"
                    aria-describedby="menu-item-delete-modal-description"
                >
                    <Box
                        sx={style}
                        dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                    >
                        <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
                            <div className="flex justify-between items-center mb-8 border-b px-4 pb-0 pt-5">
                                <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('users.add_users_btn')}</h2>

                                <div>
                                    <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                                </div>
                            </div>

                            <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.first_name')} <Astrisks /></label>
                                    <input type="text" id="firstName"
                                        {...register('firstName', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}
                                        minLength={3}
                                        maxLength={50}

                                    />

                                    {errors?.firstName?.message ? <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.first_name_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.last_name')} <Astrisks /></label>
                                    <input type="text" id="lastName"
                                        {...register('lastName', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}
                                        minLength={3}
                                        maxLength={50}

                                    />

                                    {errors?.lastName?.message ? <p className="capitalize text-sm text-danger">{errors?.lastName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.last_name_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]' dir='ltr'>
                                    <label dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>{t('users.form.phone')} <Astrisks /></label>
                                    {/* <input type="text" id="phoneNumber"
                                        {...register('phoneNumber',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            minLength : {
                                                value : 10,
                                                message : 'min length is 10 digits'
                                            },
                                            maxLength : {
                                                value : 22,
                                                message : 'max length is 22 digits'
                                            },                                   
                                            pattern:{
                                                value: /^(\+)?\d+$/,
                                                message: 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        })} 
                                        minLength={10}
                                        maxLength={22}
                                    /> */}

                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            minLength: {
                                                value: 10,
                                                message: t('common.min_length', { length: '10' })
                                            },
                                            maxLength: {
                                                value: 22,
                                                message: t('common.max_length', { length: '22' })
                                            },
                                            pattern: {
                                                value: /^(\+)?\d+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالارقام وعلامه + بالبداية فقط' : 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                className='mb-1 [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:h-auto [&>input]:p-3 [&>input]:pl-12 [&>input]:border-none [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor'
                                                value={value}
                                                dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                                                onChange={(val, country) => {
                                                    onChange("+" + val); // Add '+' sign before the value
                                                }}
                                                country={'sa'}
                                                minLength={10}
                                                maxLength={22}
                                                preserveOrder={['+', '']}
                                                enableSearch={true}
                                                disableSearchIcon
                                            />
                                        )}
                                    />

                                    {errors?.phoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.phoneNumber?.message}</p> : <p className={`capitalize text-[11px] text-[#B5B5C3] ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : 'text-left'} `}>{t('users.form.phone_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label>{t('users.form.email')} <Astrisks /></label>
                                    <input type="email" id="email"
                                        {...register('email', {
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                            },
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            }
                                        })} />

                                    {errors?.email?.message ? <p className="capitalize text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.email_helper')}</p>}
                                </div>

                                <div className='basis-full md:basis-[48%]'>
                                    <label htmlFor="gender">{t('users.form.gender')} <Astrisks /></label>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <select onChange={(e) => { onChange(e) }} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                    <option value="" disabled>
                                                        {t('users.form.gender_helper')}
                                                    </option>
                                                    <option value={'MALE'}>male</option>
                                                    <option value={'FEMALE'}>female</option>
                                                </select>
                                                {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.gender_helper')}</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                {/* <div>
                                    <label>National ID/ Iqama <Astrisks /></label>
                                    <input type="text" id="nationalId"
                                    {...register('nationalId',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        }
                                    })} />

                                    {errors?.nationalId?.message ? <p className="capitalize text-sm text-danger">{errors?.nationalId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter Your ID</p>}
                                </div> */}

                                <div className="basis-full md:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                    <label>{t('users.form.select_role')} <Astrisks /></label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name=""
                                            value={roleType}
                                            rules={{ required: t('common.required') }}
                                            {...register('roleId', {
                                                required: {
                                                    value: true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({ field, fieldState: { error } }) => (
                                                <>
                                                    <Select {...field}
                                                        displayEmpty
                                                        inputRef={{ ...register('roleId') }}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="roleId"
                                                        value={roleType}
                                                        onChange={handleroleTypeSelectChange}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-tzsjye': { backgroundColor: '#f3f6f9' } }}
                                                    >
                                                        <MenuItem value={''} disabled selected={true}>
                                                            {t('users.form.select_role_helper')}
                                                        </MenuItem>
                                                        {roles?.data?.map((role, index) => (
                                                            <MenuItem value={role} key={index}>
                                                                <ListItemText className='capitalize' primary={role?.roleName?.toLowerCase()?.replace(/_/g, " ")} />
                                                            </MenuItem>
                                                        ))}


                                                    </Select>
                                                </>
                                            )}

                                        />

                                    </FormControl>

                                    {errors?.roleId?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.roleId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('users.form.select_role_helper')}</p>}
                                </div>

                                {roleName === 'BRANCH_ADMIN' ?

                                    <div className="basis-full [&>div]:bg-[#f3f6f9]">
                                        <label>{t('users.form.select_branch')} <Astrisks /></label>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name=""
                                                value={branch}
                                                rules={{ required: t('common.required') }}
                                                {...register('branchId', {
                                                    required: {
                                                        value: true,
                                                        message: t('common.required')
                                                    }
                                                })}
                                                render={({ field, fieldState: { error } }) => (
                                                    <>
                                                        <Select {...field}
                                                            displayEmpty
                                                            inputRef={{ ...register('branchId') }}
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="branchId"
                                                            value={branch}
                                                            onChange={handleBranchSelectChange}
                                                            // renderValue={(selected) => selected.join(', ')}
                                                            sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-tzsjye': { backgroundColor: '#f3f6f9' } }}
                                                        >
                                                            <MenuItem value={''} disabled>
                                                                {t('users.form.select_branch_helper')}
                                                            </MenuItem>
                                                            {branches?.data?.map((branch) => (
                                                                <MenuItem value={branch?.id} key={branch?.id}>
                                                                    <ListItemText className='capitalize' primary={branch?.name?.toLowerCase()?.replace(/_/g, " ")} />
                                                                </MenuItem>
                                                            ))}


                                                        </Select>
                                                    </>
                                                )}

                                            />

                                        </FormControl>

                                        {errors?.branch_id?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.branch_id?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('users.form.select_branch_helper')}</p>}
                                    </div> : null}

                            </div>

                            <div className={`add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2 ${localStorage?.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                                <Button type='submit' disabled={users?.loading} className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>{t('users.form.add_btn')}</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>

                {users?.loading ? <Overlay /> : null}
            </>

        </article>
    );
}

export default Users;