import ReactApexChart from 'react-apexcharts';
import chartIconPurple from '../../Assets/imgs/chart-icon-purple.svg'
import chartIconYellow from '../../Assets/imgs/chart-icon-yellow.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getRevenue } from '../Redux/Slice/Report/report';
import { Box, Button, MenuItem, Modal, Select } from '@mui/material';
import Overlay from '../Common/overlay'
import { useTranslation } from 'react-i18next';

const Revenue = () => {
    const { t, i18n } = useTranslation()

    const dispatch = useDispatch()
    const revenues = useSelector(state => state?.report)
    const [val, setval] = useState('daily')

    let dateRange = 'daily'
    useEffect(() => {
        dispatch(getRevenue({ dateRange }))
    }, [dispatch])

    const handleRevenueChange = (range) => {
        let dateRange = range?.target?.value
        dispatch(getRevenue({ dateRange }))
        setval(dateRange)
    }

    const handleModalRevenueChange = (range) => {
        let dateRange = range?.target?.value
        dispatch(getRevenue({ dateRange }))
    }

    // console.log(revenues?.revenues?.data?.data);

    let takeinShare = revenues?.revenues?.data?.data?.reduce((acc, obj) => {
        return obj?.takeinShare ? parseInt(acc) + parseInt(obj?.takeinShare) : acc;
    }, 0);


    let restaurantShare = revenues?.revenues?.data?.data?.reduce((acc, obj) => {
        return obj?.restaurantShare ? parseInt(acc) + parseInt(obj?.restaurantShare ? obj?.restaurantShare : 0) : acc
    }, 0)

    function filterize(values) {
        return values?.filter(value => !isNaN(value));
    }

    let totalValues = [...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.restaurantShare)) || []), ...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.takeinShare)) || [])]
    let rowNo = 5
    let summurizedValues = [...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.restaurantShare)) || []), ...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.takeinShare)) || [])]?.slice(0, rowNo)
    let filteredValues = filterize(summurizedValues);
    let filteredTotalValues = filterize(totalValues);

    const options = {
        chart: {
            type: 'line',
            height: '100%',
            //   dropShadow: {
            //     enabled: true,
            //     color: '#000',
            //     top: 18,
            //     left: 7,
            //     blur: 10,
            //     opacity: 0.2
            //   },
            // this is used for display zoom buttons
            toolbar: {
                show: false
            }
        },
        colors: ['#8950FC', '#D9AE56'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        title: {
            text: '',
            show: false, // Hide the chart title
            floating: true,
            align: 'center',
            margin: 35,
            offsetY: 20,
            style: {
                fontSize: '25px'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.4
            },
        },
        markers: {
            size: 0 // make dots appears on the line to specify where the data is
        },
        xaxis: {
            categories: revenues?.revenues?.data?.data?.map((item) => item?.date) || [],
            // title: {
            // text: 'Month'
            // }
            labels: {
                // trim : true
                style: {
                    fontSize: '11px',
                    color: "#ddd"
                },
            },
            // axisBorder: {
            //     show: true,
            //     color: '#78909c',
            //     height: 1,
            //     width: '100%',
            //     offsetX: 0,
            //     offsetY: 0
            //   },
        },
        yaxis: {
            labels: {
                align: 'center'
            },
            opposite: localStorage.getItem('i18nextLng') === 'ar' ? true : false,
            //   title: {
            //     text: 'Temperature'
            //   },
            min: Math?.min(...filteredValues),
            max: Math?.max(...filteredValues),
            tickAmount: 4, // define the number of rows on the y axis
            // tickPlacment : 'between'
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            offsetY: -20,
            offsetX: -25,
            itemMargin: {
                horizontal: 20, // Set the horizontal margin between legend items to 10 pixels
                // vertical: 0, // Set the vertical margin between legend items to 0 pixels
            },
            markers: {
                radius: 0, // Set the radius of the legend markers to 0
                height: 10,
                width: 10,
                offsetX: localStorage?.getItem('i18nextLng') === 'ar' ? 5 : 0,
                offsetY: 0,
                shape: 'square', // Set the shape of the legend markers to "square"
            }
        }
    };

    const series = [
        {
            name: t("dashboard.revenue.chart.restaurantShare"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.restaurantShare ? item?.restaurantShare : 0)?.slice(0, rowNo) || []
        },
        {
            name: t("dashboard.revenue.chart.takeinShare"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.takeinShare)?.slice(0, rowNo) || []
        }
    ]

    // console.log(Math?.min(...summurizedValues));
    const [openModal, setopenModal] = useState(false)
    const handelOpen = () => {
        setopenModal(true)
        let dateRange = 'daily'
        dispatch(getRevenue({ dateRange }))
    }
    const handelClose = () => {
        setopenModal(false)
        let dateRange = 'daily'
        dispatch(getRevenue({ dateRange }))
        setval('daily')
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        minWidth: '500px',
        height: '90%',
        // minHeight: '600px',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px: 2,
        borderRadius: 5,
    };

    const modalOptions = {
        chart: {
            type: 'line',
            height: '100%',
            //   dropShadow: {
            //     enabled: true,
            //     color: '#000',
            //     top: 18,
            //     left: 7,
            //     blur: 10,
            //     opacity: 0.2
            //   },
            // this is used for display zoom buttons
            toolbar: {
                show: true
            }
        },
        colors: ['#8950FC', '#D9AE56'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        title: {
            text: '',
            show: false, // Hide the chart title
            floating: true,
            align: 'center',
            margin: 35,
            offsetY: 20,
            style: {
                fontSize: '25px'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.4
            },
        },
        markers: {
            size: 0 // make dots appears on the line to specify where the data is
        },
        xaxis: {
            categories: revenues?.revenues?.data?.data?.map((item) => item?.date) || [],
            range: 15,
            // title: {
            // text: 'Month'
            // }
            labels: {
                // trim : true
                style: {
                    fontSize: '11px',
                    color: "#ddd"
                },
            },
            // axisBorder: {
            //     show: true,
            //     color: '#78909c',
            //     height: 1,
            //     width: '100%',
            //     offsetX: 0,
            //     offsetY: 0
            //   },
        },
        yaxis: {
            labels: {
                align: 'center'
            },
            opposite: localStorage.getItem('i18nextLng') === 'ar' ? true : false,
            min: Math?.min(...filteredTotalValues),
            max: Math?.max(...filteredTotalValues),
            // tickAmount : 4, // define the number of rows on the y axis
            // tickPlacment : 'between'
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            offsetY: localStorage?.getItem('i18nextLng') === 'ar' ? 2 : -20,
            offsetX: -25,
            itemMargin: {
                horizontal: 20, // Set the horizontal margin between legend items to 10 pixels
                // vertical: 0, // Set the vertical margin between legend items to 0 pixels
            },
            markers: {
                radius: 0, // Set the radius of the legend markers to 0
                height: 10,
                width: 10,
                offsetX: localStorage?.getItem('i18nextLng') === 'ar' ? 5 : 0,
                offsetY: 0,
                shape: 'square', // Set the shape of the legend markers to "square"
            }
        }
    };

    const modalSeries = [
        {
            name: t("dashboard.revenue.chart.restaurantShare"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.restaurantShare) || []
        },
        {
            name: t("dashboard.revenue.chart.takeinShare"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.takeinShare) || []
        }
    ]
    return (
        <div className='revenue-component-wrapper bg-white pr-4 py-3 rounded-md shadow-sm h-full flex flex-col' dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'} >
            <div className='px-4 pr-0'>
                <section className='chart-title flex justify-between items-start'>
                    <div className='flex items-center gap-x-2'>
                        <h2 className='font-bold text-sm lg:text-lg text-[#464E5F]'>{t("dashboard.revenue.title")}</h2>
                        <Button className='text-white bg-mainColor text-xs py-2 capitalize' onClick={handelOpen}>{t("dashboard.revenue.button")}</Button>
                    </div>

                    <div>
                        <Select
                            displayEmpty
                            // placeholder="Please Select Menu item.."
                            labelId="demo-multiple-checkbox-label"
                            id="menuItemId"
                            value={val}
                            onChange={handleRevenueChange}
                            // label='Please Select Menu Item'
                            className='min-w-[110px] [&>div]:bg-[#f3f6f9]'
                            sx={{ '&>*': { textTransform: 'capitalize' }, '& .MuiInputBase-input': { fontFamily: "Playfair Display , sans", fontWeight: "600" }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '5px 14px' }, '& fieldset': { border: 0 } }}
                        >
                            <MenuItem value={'daily'} selected className='capitalize'>
                                {t("dashboard.revenue.dropdown.daily")}
                            </MenuItem>
                            <MenuItem value={'weekly'} className='capitalize'>
                                {t("dashboard.revenue.dropdown.weekly")}
                            </MenuItem>
                            <MenuItem value={'monthly'} className='capitalize'>
                                {t("dashboard.revenue.dropdown.monthly")}
                            </MenuItem>

                        </Select>
                    </div>
                </section>

                <section className="sales-wrapper text-[11px] text-[#B5B5C3] font-[500] flex " dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
                    <span>{revenues?.revenues?.data?.totalSales} {t("dashboard.revenue.sales")}</span>
                </section>

                <section className="revenue-data-numbers mt-3 mb-2 flex gap-x-10">
                    <div className='text-center w-fit'>
                        <label className='text-[#999999] text-sm capitalize'>{t("dashboard.revenue.chart.restaurantShare")}</label>
                        <p className='flex'><img src={chartIconPurple} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>SAR {restaurantShare?.toLocaleString()}K</span></p>
                    </div>

                    <div className='text-center w-fit'>
                        <label className='text-[#999999] text-sm capitalize'>{t("dashboard.revenue.chart.takeinShare")}</label>
                        <p className='flex'><img src={chartIconYellow} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>SAR {takeinShare?.toLocaleString()}K</span></p>
                    </div>
                </section>
            </div>

            <section className="chart-wrapper grow">
                <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    width="100%"
                    height="100%"
                />
            </section>

            {revenues?.loading ? <Overlay /> : null}

            <>
                <Modal
                    open={openModal}
                    onClose={handelClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    dir
                >
                    <Box sx={style} className='overflow-auto' dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
                        <div className="flex justify-between items-center border-b pb-2 mb-3">
                            <h1 className="text-xl text-[#333] capitalize font-semibold">{t('dashboard.revenue.chart.title')}</h1>
                        </div>

                        <div>

                            <div className='px-4 pr-0'>
                                <section className='chart-title flex justify-between items-start'>
                                    <div>
                                        <h2 className='font-bold text-sm lg:text-lg text-[#464E5F] capitalize'>{t("dashboard.revenue.title")}</h2>
                                    </div>

                                    <div>
                                        <Select
                                            displayEmpty
                                            // placeholder="Please Select Menu item.."
                                            labelId="demo-multiple-checkbox-label"
                                            id="menuItemId"
                                            value={val}
                                            onChange={handleRevenueChange}
                                            // label='Please Select Menu Item'
                                            className='min-w-[140px]'
                                            sx={{ '&>*': { textTransform: 'capitalize' }, '& .MuiInputBase-input': { fontFamily: "Playfair Display , sans", fontWeight: "600" }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '8px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-tzsjye': { backgroundColor: '#f3f6f9' } }}
                                        >
                                            <MenuItem value={'daily'} selected className='capitalize'>
                                                {t("dashboard.revenue.dropdown.daily")}
                                            </MenuItem>
                                            <MenuItem value={'weekly'} className='capitalize'>
                                                {t("dashboard.revenue.dropdown.weekly")}
                                            </MenuItem>
                                            <MenuItem value={'monthly'} className='capitalize'>
                                                {t("dashboard.revenue.dropdown.monthly")}
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </section>

                                <section className="sales-wrapper text-[11px] text-[#B5B5C3] font-[500]">
                                    <span>{revenues?.revenues?.data?.totalSales} {t("dashboard.revenue.sales")}</span>
                                </section>

                                <section className="revenue-data-numbers mt-3 mb-2 flex gap-x-10">
                                    <div className='text-center w-fit'>
                                        <label className='text-[#999999] text-sm capitalize'>{t("dashboard.revenue.chart.restaurantShare")}</label>
                                        <p className='flex'><img src={chartIconPurple} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>SAR {restaurantShare?.toLocaleString()}K</span></p>
                                    </div>

                                    <div className='text-center w-fit'>
                                        <label className='text-[#999999] text-sm capitalize'>{t("dashboard.revenue.chart.takeinShare")}</label>
                                        <p className='flex'><img src={chartIconYellow} alt='chart-icon' className={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`} /> <span className='text-[#464E5F] font-bold'>SAR {takeinShare?.toLocaleString()}K</span></p>
                                    </div>
                                </section>
                            </div>

                            <section className="chart-wrapper grow w-full h-full min-h-[400px]">
                                <ReactApexChart
                                    options={modalOptions}
                                    series={modalSeries}
                                    type="line"
                                    width="100%"
                                    height="100%"
                                    className='h-full'
                                />
                            </section>

                            {revenues?.loading ? <Overlay /> : null}
                        </div>

                    </Box>
                </Modal>
            </>
        </div>
    );
}


export default Revenue;