import './timeline.scss'
import { formatDateToUserTimezone } from '../Common/dateFormate';
const TimeLine = ({ event }) => {

    return (
        <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'timeline-container-ar' : 'timeline-container'}`}>

            {event?.length !== 0 ?
                event?.map((item, index) => (
                    item?.name === 'NEW' ? null
                        : <div className="time-line-event -mt-1 table table-fixed relative min-h-[60px]" key={index}>
                            <div className={`active`}></div>

                            <div className={`timeline-text capitalize table-cell${localStorage.getItem('i18nextLng') === 'ar' ? 'pr-4 mr-4' : 'pl-4 ml-3'}`}>
                                <p className='text-[#464E5F] text-sm capitalize'>{localStorage.getItem('i18nextLng') === 'ar' ? item.nameAr?.replace('_/g', '')?.toLowerCase() : item.name?.replace(/_/g, ' ')?.toLowerCase()}</p>
                                <p className='text-[#B5B5C3] text-[11px]'>{formatDateToUserTimezone(item.createdAt)}</p>
                            </div>
                        </div>
                ))

                : <p className='text-center font-semibold text-mainColor text-lg'>{localStorage.getItem('i18nextLng') === 'ar' ? 'لا يوجد شيئ هنا' : 'Nothing Here.!'}</p>}

        </div>
    );
}

export default TimeLine;