
import { Button, FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from '@mui/material';
import { FaEdit } from 'react-icons/fa'
import { useCallback, useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { IoMdAddCircle, IoMdEye } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';
import { openHandler } from '../../Components/Redux/Slice/common/overlaySlice';
import discount from '../../Assets/imgs/discount.svg'
import AddOfferModal from './Offers-Components/addOfferModal';
import { deleteOffer, getOffers, toggleOfferActivation } from '../../Components/Redux/Slice/Offers/offers';
import EditOfferModal from './Offers-Components/editOfferModal';
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { LuCheckCircle, LuDelete } from 'react-icons/lu'
import WarningModal from './Offers-Components/warningModal';
import Overlay from '../../Components/Common/overlay';
import ColumnSelection from '../../Components/Common/columnSelection';
import { useRemoteSort } from '../../Components/Common/sortHook';
import NoDataEmoji from '../../Components/Common/nodataEmoje';
import { TableStyles } from '../../Components/Common/tableStyle';
import { useTranslation } from 'react-i18next';
import { resetPage, resetSearch, resetSort } from '../../Components/Redux/Slice/ResetPagination/resetPagination';
import { dateOnlyFormate, formatDateToUserTimezone } from '../../Components/Common/dateFormate';
import { BsDot } from 'react-icons/bs';

//Custome Cell UI for the data table action column
function ActionCell(row) {
    // console.log(row);
    const { t, i18n } = useTranslation()
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = () => setopenModal(true)
    const handleCloseModal = () => setopenModal(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);

    const { auth } = useSelector(state => state)

    //warning modal state
    const [openWarningModal, setopenWarningModal] = useState(false);
    const [warningType, setwarningType] = useState(null);

    const handleOpenWarningModal = (type) => {
        setopenWarningModal(true)
        setwarningType(type)
        // console.log(type);
    };
    const handleCloseWarningModal = () => setopenWarningModal(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let page = 1,
        size = 10

    const toggleOfferActivaition = (status) => {
        dispatch(toggleOfferActivation({ status, id: row?.id })).then(() => {
            handleCloseWarningModal()
            dispatch(getOffers({ page, size }))
            dispatch(resetPage());
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
        })
        // console.log(status);
    }

    const deleteFunc = () => {
        dispatch(deleteOffer(row?.id)).then(() => {
            handleCloseWarningModal()
            dispatch(getOffers({ page, size }))
            dispatch(resetSearch());
            dispatch(resetPage());
        })
    }
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
                sx={{ '& .css-6hp17o-MuiList-root-MuiMenu-list': { padding: 0 } }}
            >
                <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? '[&>li>svg]:ml-2 [&>li]:flex [&>li]:flex-row-reverse' : '[&>li>svg]:mr-2'} [&>li]:mb-2  [&>li>svg]:text-xl rounded-md overflow-hidden capitalize`}>
                    <MenuItem className='text-mainColor hover:bg-[#b3c0f742]' onClick={handleOpenModal}>
                        {auth?.role === 'BRANCH_ADMIN' ? localStorage.getItem('i18nextLng') === 'ar' ? <><IoMdEye />  عرض</> : <><IoMdEye />  View</> : localStorage.getItem('i18nextLng') === 'ar' ? <><FaEdit /> تعديل</> : <><FaEdit /> Edit</>}
                    </MenuItem>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> Contact Courier</MenuItem> */}
                    {/* {row?.isAvailable ? <MenuItem onClick={()=>toggleOfferActivaition('deactivate')} className='text-mainRed hover:bg-[#c6345b42]'><LuDelete />Deactivate</MenuItem> : <MenuItem onClick={()=>toggleOfferActivaition('reactivate')} className='text-mainGreen hover:bg-[#5cac7d42]'><LuCheckCircle />Active</MenuItem>} */}
                    {auth?.role === 'BRANCH_ADMIN' ? null : row?.isAvailable ? <MenuItem onClick={() => handleOpenWarningModal('deactivate')} className='text-mainRed hover:bg-[#c6345b42]'>{localStorage.getItem('i18nextLng') === 'ar' ? <><LuDelete />غير نشط</> : <><LuDelete />Deactivate</>}</MenuItem> : <MenuItem onClick={() => toggleOfferActivaition('reactivate')} className='text-mainGreen hover:bg-[#5cac7d42]'>{localStorage.getItem('i18nextLng') === 'ar' ? <><LuCheckCircle /> نشط</> : <><LuCheckCircle /> Active</>}</MenuItem>}
                    {auth?.role === 'BRANCH_ADMIN' ? null : <MenuItem className='text-[#e93131] hover:bg-[#e931314b]' onClick={() => handleOpenWarningModal('delete')}>{localStorage.getItem('i18nextLng') === 'ar' ? <><MdOutlineDeleteSweep /> مسح</> : <><MdOutlineDeleteSweep /> Delete</>}</MenuItem>}

                </div>
            </Menu>
            {/* dispatch(deleteOffer(row?.id)).then( ()=>dispatch(getOffers({page,size})) ) */}

            <EditOfferModal open={openModal} close={handleCloseModal} data={row} type={'edit'} />
            <WarningModal open={openWarningModal} close={handleCloseWarningModal} type={warningType} deleteFunc={deleteFunc} toggleOfferActivaition={toggleOfferActivaition} />
        </div>
    );
}

// test component to position an element respective to its distance from window
const TestComp = () => {
    const ref = useRef(null)
    const [show, setshow] = useState(false)
    const [topDis, settopDis] = useState(0)


    const distance = () => {
        let distance = ref.current.getBoundingClientRect() // to calculate the distance of the component from the window
        // console.log(distance.top);
        settopDis(distance.top)
    }
    const showHandler = () => {
        setshow(!show)
    }

    return (
        <>
            <div ref={ref} onClick={distance} className='relative'>
                <ListItemButton onClick={showHandler}>Test</ListItemButton>

                {show ? <div className={`absolute w-20 h-20 shadow-lg bg-slate-700 left-0 top-${topDis} text-white`}>
                    kdfjlsjdf
                </div> : null}
            </div>

        </>
    )
}

const Offers = () => {
    const { t, i18n } = useTranslation()

    const [activeTab, setActiveTab] = useState(0);
    const [activeRowData, setactiveRowData] = useState(null);
    const [open, setopen] = useState(false)
    const handleOpen = () => setopen(true)
    const handleClose = () => setopen(false)
    const [searchValue, setsearchValue] = useState('')
    const [status, setstatus] = useState('')
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    const { offers } = useSelector(state => state)
    const { auth } = useSelector(state => state)

    const { currentPage } = useSelector((state) => state?.resetPagination);
    const { toggle, search, sort } = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    useEffect(() => {
        setsearchValue('')
    }, [search])

    const btns = [
        { labelAr: "الكل", label: 'all', param: `ALL` },
        { labelAr: "النشط", label: 'active', param: `ACTIVE` },
        { labelAr: "الغير نشط", label: 'Deactivated', param: `DEACTIVATED` },
    ]

    const filterBtn = (item, index) => {
        setActiveTab(index)
        let status = item?.param
        setstatus(status)
        dispatch(getOffers({ status }))
    }

    useEffect(() => {
        // console.log(filter);
    }, [status])

    // console.log(selectedDate);
    // console.log(selectedDate.$M+1); display month but add 1 since it start with month 0

    //data table section

    const columns = [
        {
            id: 'offerName',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم العرض' : 'Offer Name'}`,
            cell: row => (
                <div className='flex items-center'>
                    {/* <div className='w-5 h-5 rounded-[50%] mr-[6px]'>
                        <img className='w-full h-full rounded-[50%]' src={row?.image} loading='lazy' alt={row?.name} data-tag="allowRowEvents" />
                    </div> */}
                    <p data-tag="allowRowEvents">{row?.name}</p>
                </div>
            ),
            sortable: true,
        },
        {
            id: 'offerArabicName',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم العرض بالعربية' : 'Offer Arabic Name'}`,
            selector: row => row?.nameAr,
            sortable: true,
        },
        {
            id: 'createdAt',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تاريخ الانشاء' : 'Created at'}`,
            selector: row => formatDateToUserTimezone(row?.createdAt),
            sortable: true,
        },
        {
            id: 'expirationDate',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'ينتهى في' : 'Expiration Date'}`,
            selector: row => formatDateToUserTimezone(row?.expirationDate),
            sortable: true,
        },
        {
            id: 'discountType',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'نوع الخصم' : 'discount type'}`,
            selector: row => <span className='font-semibold'>{row?.discountType === 'PERCENTAGE' ? t('common.percentage') : row?.discountType === 'AMOUNT' ? t('common.amount') : null}</span>,
            sortable: true,
        },
        {
            id: 'offerValue',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'قيمة العرض' : 'Offer Value'}`,
            selector: row => row?.discountType === 'PERCENTAGE' ? row?.offerValue + "%" : <span>{row?.offerValue} <span className='font-semibold text-mainGreen'>{t('common.sar')}</span></span>,
            sortable: true,
        },
        {
            id: 'couponCode',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'كود الكوبون' : 'Coupon Code'}`,
            selector: row => <span className='text-mainColor font-semibold'>{row?.couponCode}</span>,
            sortable: true,
        },
        {
            id: 'description',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الوصف' : 'description'}`,
            cell: row => <div className='offers-desc-cont whitespace-[wrap] max-h-[50px] overflow-hidden overflow-y-auto pr-1'>{row?.description}</div>,
            minWidth: '245px'
        },
        {
            id: 'status',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الحالة' : 'Status'}`,
            cell: row => <div className='font-semibold'>{row?.isAvailable ? <span className='text-mainGreen bg-mainGreen bg-opacity-10 px-2 py-1 rounded-md min-w-[110px] text-center block capitalize'>{t('common.active')}</span> : <span className='text-mainRed bg-mainRed bg-opacity-10 px-2 py-1 rounded-md min-w-[110px] text-center block capitalize'>{t('common.deactivated')}</span>}</div>,
        },
        {
            id: 'createdBy',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'انشأ بواسطة' : 'Created By'}`,
            selector: row => <span className='font-semibold'>{row?.creatorName}</span>,
            sortable: true,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: ActionCell
        },
    ];



    const data = offers?.offers?.data

    // open calander overlay
    const { dateOverlay } = useSelector(state => state.overlay)
    const dispatch = useDispatch()

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }

    useEffect(() => {
        //   console.log(activeRowData);
    }, [activeRowData])

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    // useEffect(() => {
    //     dispatch(getOffers({page,size,searchValue,col,dir,status}))
    // }, [dispatch, page, size,searchValue])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    // console.log(offers);

    // const handleRemoteSort = (col,dir)=>{
    //     dispatch(getOffers({page,size,col,dir,searchValue}))
    //     // console.log(col);
    // }

    const handleSearch = () => {
        dispatch(getOffers({ searchValue })).then(() => dispatch(resetPage()))
    }

    const handleReset = () => {
        dispatch(getOffers({ page, size })).then(() => dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
        //   console.log(searchValue);
    }, [searchValue])



    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };

    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     {name:'Offer Name'},
    //     {name:'Created at'},
    //     {name:'Expiration Date'},
    //     {name:'discount type'},
    //     {name:'Offer Value'},
    //     {name:'Coupon Code'},
    //     // {name:'Final Amount'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const { handleRemoteSort, icon } = useRemoteSort(getOffers, dispatch, page, size, searchValue, null, null, status)



    // console.log(offers?.offers?.data);
    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['offerName', 'offerArabicName', 'createdAt', 'expirationDate', 'discountType', 'offerValue', 'status', 'createdBy', 'actions']);

    const [openMenu, setopenMenu] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpenMenu = () => {
        setopenMenu(true)
    }
    const handleCloseMenu = () => {
        setopenMenu(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    const { file } = useSelector(state => state)

    useEffect(() => {
        setSelectedColumns(['offerName', 'offerArabicName', 'createdAt', 'expirationDate', 'discountType', 'offerValue', 'status', 'createdBy', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    return (
        <article className="offers-wrapper pb-5">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('offers.title')}</h1>
            </section>

            <section className={`flex items-center justify-end gap-x-3 mt-4 ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                {auth?.role === 'BRANCH_ADMIN' ? null
                    :
                    <Button onClick={handleOpen} className={`capitalize text-white bg-mainGreen rounded-md p-[6px] gap-x-1 flex items-center ${localStorage.getItem('i18nextLng')} ? 'pl-2' : 'pr-2'}`}> <IoMdAddCircle className={`w-7 h-7 rounded-[50%] ${localStorage.getItem('i18nextLng')} ? 'ml-2' : 'mr-2' `} /> <span className='text-sm font-playfair'>{t('offers.add_new_offer_btn')}</span></Button>
                }

                <div className="flex">
                    <ColumnSelection columns={columns} handleOpen={handleOpenMenu} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openMenu} close={handleCloseMenu} />
                    {/* <Button onClick={handleOpenMenu} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                </div>
            </section>

            <section className="offers-control-wrapper flex justify-center lg:justify-between items-center mt-5 w-full">
                <div className='left-actions flex items-center'>

                    <div className="offers-filter-wrapper flex justify-evenly [&>div]:mr-2">
                        <div className="px-1 py-1 bg-[#ffffff] rounded-sm">
                            {btns?.map((item, index) => {
                                return (
                                    <button key={index} className={`capitalize font-bold text-sm text-[#333] px-4 py-2 lg:px-2 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                        onClick={() => filterBtn(item, index)}
                                    >
                                        {localStorage.getItem('i18nextLng') === 'ar' ? item.labelAr : item.label}
                                    </button>
                                )
                            })}
                        </div>
                    </div>

                    {/* <div className='mr-2'>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div> */}

                    {/* <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div> */}
                </div>


                <div className=''>
                    <FormControl
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ m: 1, width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            value={searchValue}
                            placeholder={localStorage.getItem('i18nextLng') === 'ar' ? 'ابحث في العروض...' : 'Search Offers...'}
                            onChange={(e) => {
                                setsearchValue(e.target.value)
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                    dispatch(resetPage())
                                }
                            }}
                            startAdornment={
                                <InputAdornment position="start">

                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">

                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={size}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={offers?.offers?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            // selectableRows
                            // selectableRowsHighlight
                            // onSelectedRowsChange={(e)=>console.log(e)}
                            onRowClicked={(row) => setactiveRowData(row)}
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage={true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />
                    </>
                }
                {/* {offers?.loading ?
                <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                    <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                </div> : null} */}
            </section>

            <section className='offer-preview-wrapper mt-5'>
                <div className='offer-preview-title font-semibold mb-3 text-[#464E5F] capitalize'>
                    <h2>{t('offers.offer_preview')}</h2>
                </div>

                <div className='offer-preview w-full flex h-[250px] bg-white justify-center items-center rounded-md'>
                    <div className='relative bg-mainGreen rounded-lg w-[90%] lg:w-[75%] m-auto h-[80px] max-w-[550px]'>
                        <div className={`discount absolute top-0 w-[70px] h-[70px] text-center ${localStorage.getItem('i18nextLng') === 'ar' ? 'right-3' : 'left-3'}`}>
                            <div className='discount-wrapper relative w-full h-full'>
                                <img src={discount} alt='discount' className='absolute w-full h-full' />
                                <div className='absolute top-0 [&>p]:m-0 [&>p]:p-0 [&>p]:leading-[1] w-full capitalize font-[500] mt-1 h-[90%] flex flex-wrap items-center justify-center [&>p]:basis-full'>
                                    <div>
                                        <p className='text-[17px] text-white text-sm'>{activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'}</p>
                                        <p className='text-[17px] text-white text-sm'>off</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`w-[83%] pt-4 ${localStorage.getItem('i18nextLng') === 'ar' ? 'mr-auto' : 'ml-auto'}`}>
                            <p className='font-semibold text-white'> {localStorage.getItem('i18nextLng') === 'ar' ? 'خصم يصل الى' : ' up to'} {activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'} {localStorage.getItem('i18nextLng') === 'ar' ? 'استخدم كود' : ' Discount use'} “{activeRowData ? activeRowData?.couponCode : 'COUPON CODE'}” </p>
                            {/* <p className='text-white font-light text-[13px] mt-1'>Try it now EXP {activeRowData ? new Date(activeRowData?.expirationDate).toLocaleDateString(undefined,options) : '-/-/--'}</p> */}
                            <p className='text-white font-light text-[13px] mt-1'>{localStorage.getItem('i18nextLng') === 'ar' ? 'جربه الان , العرض ينتهى في' : 'Try it now EXP'} {activeRowData ? activeRowData?.expirationDate?.substring(0, 10) : '-/-/--'}</p>
                        </div>
                    </div>
                </div>
            </section>

            <AddOfferModal open={open} close={handleClose} />

            {file?.loading || offers?.loading ? <Overlay /> : null}
        </article>
    );
}

export default Offers;